@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto+Slab:wght@100;400;700&display=swap");

/* Colors */
$jrm-blue: #27246a;
$jrm-dark: #2b2e3a;
$jrm-dark-grey: #34384b;
$jrm-dark-blue: #003049;
$jrm-dark-blue-opaque: #330d7566;
$jrm-red: #981b1e;
$jrm-orange: #f77f00;
$jrm-yellow: #fcbf49;
$jrm-gold: #eba222;
$jrm-green: #527858;
$jrm-light-blue: #078282ff;
$jrm-beige: #eae2b7;
$jrm-maroon: #3c0919;
$jrm-grey: #b1b1b1;
$jrm-light-grey: #d8d7d7;
$jrm-light-green: #c4d6b0;
$title-text: #31363a;
$header-text: #3d4449;
$text: #404a50;
$subtitle: #919191;
$light: #aaaaaa;
$light-light: #ededed;
$social-media: #bbbbbb;
$faded-blue: #20245f4d;
$faded: #0000007a;
$clear: #00000000;

/* Fonts */
$title-font-bold: bold 36pt/1.25 "Avenir", sans-serif;
$title-font: 36pt/1.25 "Avenir", sans-serif;
$big-header-font-bold: bold 28pt/1.25 "Avenir", sans-serif;
$big-header-font: 28pt/1.25 "Avenir", sans-serif;
$header-font-bold: bold 20pt/1.25 "Avenir", sans-serif;
$header-font: 20pt/1.25 "Avenir", sans-serif;
$small-header-font-bold: bold 18pt/1.25 "Avenir", sans-serif;
$small-header-font: 18pt/1.25 "Avenir", sans-serif;
$big-content-font-bold: bold 14pt/1.25 "Avenir", sans-serif;
$big-content-font: 14pt/1.25 "Avenir", sans-serif;
$content-font-bold: bold 12pt/1.25 "Avenir", sans-serif;
$content-font: 12pt/1.25 "Avenir", sans-serif;
$small-content-font-bold: bold 10pt/1.25 "Avenir", sans-serif;
$small-content-font: 10pt/1.25 "Avenir", sans-serif;
$nav-font: 14pt/1.25 "Avenir", sans-serif;

/* Sizes */
$nav-height: 50px;

/* Misc */
// $shadow: 0px 5px 12px $jrm-dark-grey;
$shadow: none;

$section-padding: 0 5vw;
$section-title-container-margin: 7vh 0 5vh 0;

/* Navigation */
$bm-height: 30px;
$bm-width: 36px;
