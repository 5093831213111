@import "../../variables";

$skill-bar-height: 24px;

.skill-bar-container {
  height: $skill-bar-height;
  width: 100%;
  margin-bottom: 10px;
  justify-content: left;
  align-items: center;
  background-color: $jrm-light-grey;
  opacity: 1;

  .title {
    color: $light-light;
    white-space: nowrap;
    height: 100%;
    min-width: 20%;
    width: 20%;
    background-color: $jrm-green;
    margin: 0;
    font: $content-font-bold;
  }

  .percentage-container {
    height: 100%;
    width: 80%;
    align-items: left;
  }

  .percentage {
    height: 100%;
    // border: 2px solid $jrm-yellow;
    background-color: $jrm-yellow;
  }

  .value {
    text-align: right;
    padding-right: 8px;
    font-weight: bold;
    color: $subtitle;
  }
}
