@import "../../variables";

.top-button-container {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 7vh;
  right: 3vw;
  background-color: $jrm-green;
  transition: 0.3s all;

  .top-arrow {
    width: 75%;
    height: 75%;
  }

  &:hover,
  &:after {
    transform: scale(1.25, 1.25);
    background-color: $jrm-green;
  }

  &:hover,
  &:after,
  .clear {
    transform: scale(1.25, 1.25);
    background-color: $jrm-green;
  }
}

.clear {
  background-color: $faded-blue;
}
