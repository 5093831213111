@import "../../variables";

.feed-item {
  width: 250px;
  height: 350px;
  position: relative;
  margin: 10px;
  justify-content: space-between;
  border: 2px solid $jrm-green;

  .feed-item-image {
    width: inherit;
    height: 100%;
    object-fit: cover;
    transition: 0.5s all;
  }

  .feed-item-image {
    opacity: 0.2;
  }

  .feed-item-info {
    width: 100%;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: 0.5s all;

    .feed-item-title-container {
      margin-bottom: 10%;

      .title {
        font: $small-header-font-bold;
        color: $title-text;
      }

      .source-type {
        font: $big-content-font;
        color: $jrm-green;
      }
    }

    .description {
      margin-bottom: 1em;
    }
  }
}

@media only screen and (max-width: 768px) {
  .feed-item {
    .feed-item-image {
      opacity: 0.3;
    }
  }
}
