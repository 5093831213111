@import "../../variables";

.project-container-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: $faded;
  // pointer-events: none;
  z-index: 10;

  .project-container {
    height: 80vh;
    width: 70vw;
    background-color: $light-light;
    // pointer-events: none;

    .modal-navbar {
      position: relative;

      .modal-close {
        position: absolute;
        height: 2em;
        width: 2em;
        top: 10px;
        right: 20px;
        color: $jrm-grey;
        transition: 0.2s all;

        &:hover {
          color: $jrm-dark-grey;
        }
      }
    }

    .project {
      height: 100%;
      margin: 0 10vw;

      .title-container {
        .title {
          font: $big-header-font-bold;
          color: $title-text;
        }

        .tools {
          font: $big-content-font;
          color: $jrm-green;
        }
      }
    }
  }
}
