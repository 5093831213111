@import "../../variables";

.project-thumbnail-container {
  width: 310px;
  height: 310px;
  position: relative;

  .project-image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    transition: 0.5s all;
  }

  &:hover,
  &:focus {
    .project-image {
      opacity: 0.2;
    }

    .project-info {
      opacity: 1;
    }
  }

  .project-info {
    width: 100%;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.5s all;

    .project-title-container {
      margin-bottom: 10%;

      .title {
        font: $small-header-font-bold;
        color: $title-text;
      }

      .tools {
        font: $big-content-font;
        color: $jrm-green;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .project-thumbnail-container {
    width: 45vw;
    height: 45vw;

    .project-info {
      display: none;
    }
  }

  .github-calendar {
    display: none;
  }
}
