@import "../../variables";

.project-container {
  min-height: 80vh;

  .project {
    margin: 0 10vw;
    justify-content: space-around;

    .title-container {
      .title {
        font: $big-header-font-bold;
        color: $title-text;
      }

      .tools {
        font: $big-content-font;
        color: $jrm-green;
      }
    }

    .project-gallery {
      width: 100%;

      .project-image {
        max-height: 40vh;
        max-width: 100%;

        margin: 10px 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .project-container {
    padding: 5vh 0 0 0;
  }
}
