@import "../../variables";

$message-padding: 2vh 0;

body {
  font: $content-font;
}

.content {
  width: 100%;
  max-height: 100%;

  span {
    color: $jrm-green;
  }

  .message {
    padding: $message-padding;
  }
}

.homepage,
.skills,
.about-me {
  padding: 0;
  margin: 0;
  width: initial;
  min-height: 100%;
}

.skills {
  .message {
    width: 60%;
  }
}

.about {
  background-color: $light-light;
  max-height: calc(100vh - #{$nav-height});
  padding-top: 0;

  .content {
    margin-bottom: 2vh;
  }

  .section-title-container {
    margin-top: 0;
  }

  .profile-pic-container {
    height: 250px;
  }
  .profile-pic {
    height: 100%;
  }
}

.feed {
  background-color: $light-light;
  padding: 0 10vw;
}

.contact {
  background-color: $jrm-green;
  min-height: calc(40vh - #{$nav-height});
  padding-bottom: 10vh;

  .phone {
    margin: 10px;
    // width: 10em;
  }

  .email {
    margin: 10px;
    color: $header-text;
    // width: 10em;
  }

  .student-email {
    margin: 10px;
    // width: 10em;
  }

  .dot-char {
    font: $content-font;
  }
}

.projects {
  min-height: 100vh;

  .project-list {
    width: 100%;
    padding-bottom: 10vh;
  }

  .github-calendar {
    width: 100%;
    padding: 20px 0;
    margin: 20px;
    border: 4px solid $jrm-green;

    img {
      width: 80%;
    }
  }
}

#calendar-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .about,
  .projects,
  .skills {
    max-height: none;
  }

  .skills {
    .message {
      width: 100%;
    }
  }

  .contact {
    .contact-info {
      flex-direction: column;
    }
  }
}
