@import "../../variables";

.global-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 1240px;
  margin: 0 auto;
  height: 100px;
  border-bottom: 5px solid $jrm-dark-blue;

  .header-mobile {
    display: none;
    margin-bottom: 10px;
  }

  h3 {
    font: $title-font;
    color: $title-text;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 610px) {
  .header-mobile {
    display: block !important;
  }

  .header-desktop {
    display: none;
  }

  .global-header {
    padding: 0 20px;
  }
}
